/* eslint-disable */
import Fuse from 'fuse.js'
import { createApp } from 'vue'
import WordHighlighter from 'vue-word-highlighter'

import { currentDevice } from '../../core/utils/currentDevice'
import { removeDiacritics } from '../../utils/remove_diacritics'
import flightPlusHotelDetection from '../../utils/flight_plus_hotel_detection'
import { addOptionGroupToPlaceToGo } from './fastbooking.select'
import { getCleanedString } from '../../utils/utilities'
import _debounce from 'lodash/debounce';
import { FASTBOOKING_MODE_CHANGE_EVENT, FASTBOOKING_FLIGHT_TAB_CREATED_EVENT } from './constants'

import FastbookingDateRangePicker from './fastbooking.rangedatepicker'
import Destination from '../../domain/Destination'

import { sendUtagEvent } from '../../core/analytics/utag_events'

let isDestinationSelectEnabled = false
let initializingVuePlaceToGoBox = false
let callbacksAfterPlaceToGoBoxInitialization = []

// Vue  parent component
let chosen
const BASE_ID_SELECTOR = '#place-to-go-box'

// Fastbooking
const $fastbooking = currentDevice.isMobile ? $('.mobile-fastbooking-cnt') : $('.fastbooking')
const FASTBOOKING_TABS = {
  HOTELS: 'hotels',
  FLIGHTS: 'flights',
}

// Events
const DESTINATION_SELECT_INIT_EVENT = 'destination-select:initialized'
const DESTINATION_SELECT_SET_VALUES_EVENT = 'destination-select:set-values'
const DESTINATION_SELECT_SET_INPUT_TEXT = 'destination:set-input-text'
const DESTINATION_SELECT_RESET_INPUT_TEXT = 'destination:reset-input-text'
const DESTINATION_SELECT_RESET_SEARCH = 'destination:reset-search'

export const initializeVars = (destinations_data, last_searches_data, hotels_data, specialties_data, next_opening_data) => {
  const setChosenValues = () => {
    const event = new CustomEvent(DESTINATION_SELECT_SET_VALUES_EVENT, {
      detail: {
        destinations_data,
        last_searches_data,
        hotels_data,
        specialties_data,
        next_opening_data
      },
    })
    document.dispatchEvent(event)
  }
  if (!chosen) {
    document.addEventListener(DESTINATION_SELECT_INIT_EVENT, setChosenValues, { once: true })
  } else {
    setChosenValues()
  }
}

export const initializeVuePlaceToGoBox = callback => {
  if (!document.querySelector(BASE_ID_SELECTOR)) return
  Boolean(callback) && !isDestinationSelectEnabled && callbacksAfterPlaceToGoBoxInitialization.push(callback)

  if (initializingVuePlaceToGoBox) return

  initializingVuePlaceToGoBox = true
  isDestinationSelectEnabled = true

  chosen = {
    components: {
      WordHighlighter,
    },
    data() {
      return {
        // Current search
        search: '',
        searchTerm: '',
        // Default text when no destination is selected
        default_text: '',
        // Current input text
        input_text: '',
        // Data for the multiple select
        filteredDestinations: [],
        destinations_data: [],
        filteredFlightDestinations: [],
        flight_destinations: [],
        filteredLastSearches: [],
        last_searches_data: [],
        filteredHotels: [],
        filteredHotelsInPackage: [],
        hotels_data: [],
        filteredSpecialties: [],
        specialties_data: [],
        filteredAllSpecialtiesExceptMatch: [],
        next_opening_data: [],
        hotels_package: [],
        // Search initialisation value.
        minimumSearchLength: 3,
        isFlightAndHotelsTabActive: false,
      }
    },
    computed: {
      getFBState() {
        return IB.fastbooking_state.getState() ? IB.fastbooking_state.getState() : false
      },
      isCuba() {
        return document.documentElement.dataset.inMicrosite == 'ibercuba'
      },
      isIndependent() {
        return document.documentElement.dataset.inMicrosite == 'independent'
      },
      hideHotels(){
        return document.querySelector('.js-flight-and-hotels-tab')?.dataset.provider === 'HLX'
      },
      showNotFoundResultsInMobile() {
        return !this.filteredDestinations && !filteredLastSearches.length && !this.filteredHotels.length && !this.filteredSpecialties.length
      },
    },
    watch: {
      // Watch for changes in the search input
      search(newValue) {
        // If the search is less than the minimum length, don't filter
        if (newValue && newValue.length < this.minimumSearchLength) return
        if (!newValue || !newValue.length) {
          // immediately reset the results
          this.filterResults()
        } else {
          // debounce the filter
          this.filterResultsDebounced()
        }
      }
    },
    created() {
      this.filterResultsDebounced = _debounce(() => {
        this.filterResults()
      }, 300)
    },
    mounted() {
      this.default_text = document.querySelector('.chosen-select').dataset.placeholder
      this.input_text = this.default_text
      document.querySelector('.input-text').classList.remove('hidden')

      this.changePlaceholder()
      IB.fastbooking_state.setState()

      if (IB.current_microsite !== 'iberostar_pro') {
        this.loadDestinationsFromFlightAndHotelsTab()
      }

      if (window.IB.currentPageType.isPackage()) {
        this.hotels_package = JSON.parse(document.querySelector('.package-hotels-to-filter-data').textContent)
      }

      // Add event listeners for changing values outside Vue app
      this.listenForSetValues()
      this.listenForInputText()
      this.listenForResetInputText()
      this.listenForResetSearch()
      this.listenForFastbookingModeChange()
    },
    methods: {
      async loadDestinationsFromFlightAndHotelsTab() {
        const response = await flightPlusHotelDetection()
        this.flight_destinations = this.filteredFlightDestinations = Destination.mapDestinationsBasedOnProvider(response.destinations, response.provider)
        addOptionGroupToPlaceToGo(this.flight_destinations, 'flight-destinations', 'flight-destinations')
      },
      listenForSetValues() {
        document.addEventListener(DESTINATION_SELECT_SET_VALUES_EVENT, e => {
          this.setValues(e.detail)
        })
      },
      setValues({ destinations_data, last_searches_data, hotels_data, specialties_data, next_opening_data }) {
        this.destinations_data = this.filteredDestinations = this.orderArrayByTagsNumbers(destinations_data)
        this.last_searches_data = this.filteredLastSearches = last_searches_data
        this.hotels_data = this.filteredHotels = this.orderArrayByTagsNumbers(hotels_data)
        this.specialties_data = this.filteredSpecialties = this.orderArrayByTagsNumbers(specialties_data)
        this.next_opening_data = next_opening_data

        if (window.IB.currentPageType.isPackage()) {
          this.filterHotelsInPackage()
        }
      },
      listenForInputText() {
        document.addEventListener(DESTINATION_SELECT_SET_INPUT_TEXT, e => {
          this.input_text = e.detail
          const inputText = document.querySelector('.input-text')

          addDataAttributesToInput(this.input_text, inputText)
        })
      },
      listenForFastbookingModeChange() {
        document.addEventListener(FASTBOOKING_MODE_CHANGE_EVENT, e => {
          this.isFlightAndHotelsTabActive = e.detail === FASTBOOKING_TABS.FLIGHTS
        })
      },
      listenForResetInputText() {
        document.addEventListener(DESTINATION_SELECT_RESET_INPUT_TEXT, e => {
          this.input_text = this.default_text
        })
      },

      listenForResetSearch() {
        document.addEventListener(DESTINATION_SELECT_RESET_SEARCH, e => {
          this.search = ''
        })
      },

      sendUtagEvent(params) {
        let event_name =  this.isFlightAndHotelsTabActive ? 'searcher_flight_hotel' : 'searcher_hotel'
        let event_cat =  this.isFlightAndHotelsTabActive ? 'searcher fly' : 'searcher'
        let data = params.data
        sendUtagEvent({
          data: {
            event_name,
            event_cat,
            ...data,
            event_lbl: getCleanedString(data.event_lbl),
          },
        })
      },
      /*
        Ordeno el array recibido por el número de tags que tiene el elemento y conservando el orden del resto
      */
      orderArrayByTagsNumbers(data) {
        return data.sort(function (one, other) {
          if (other.data.tags !== null && other.data.tags !== undefined && one.data.tags !== null && one.data.tags !== undefined) {
            return other.data.tags.length - one.data.tags.length
          }
          return 0
        })
      },

      fuzzysearch(title, word) {
        if (word.length < this.minimumSearchLength) return true

        word = removeDiacritics(word.toLowerCase())
        title = removeDiacritics(title.toLowerCase())

        const opt = {
          threshold: 0.2,
          tokenize: true,
          tokenSeparator: /\s+/g,
          matchAllTokens: true,
          location: 0,
          distance: 200,
          maxPatternLength: 32,
          minMatchCharLength: 1,
        }

        const fuse = new Fuse([title], opt)
        const o = fuse.search(word)
        const bln = !!o.length

        return bln
      },

      elementHasTags(element) {
        const familiar_tag = element.data.restrictions ? element.data.restrictions[0].allow_children : false
        if (familiar_tag) {
          return true
        }
        if (element.data.tags !== null && element.data.tags !== undefined) {
          return element.data.tags.length
        }
        return 0
      },

      findSearchInTitleContent(title, search) {
        if (!title) return false

        let result = true // assumes all results are true

        const words = search.split(' ')
        words.forEach(word => {
          if (!this.fuzzysearch(title, word)) result = false // Removes item from selector if it doesn't have this word
        })

        return result
      },
      findInTitleContentReturnsNoMatch(title, search) {
        let result = false // assumes all results are false

        const words = search.split(' ')
        words.forEach(word => {
          if (!this.fuzzysearch(title, word)) result = true // Remove the element from the selector if it has the word
        })

        return result
      },
      showTitleWhenElementIsEmpty(element) {
        if (!currentDevice.isMobile) return true
        return element.length
      },
      changeDateOutput(dateString) {
        if (dateString == undefined) return ''
        const datearray = dateString.split('-')
        return `${datearray[2]}/${datearray[1]}/${datearray[0]}`
      },
      highlightHoverElement(event) {
        const currentHighlightElement = document.querySelector('.active-result.highlighted')
        if (currentHighlightElement !== null) currentHighlightElement.classList.remove('highlighted')
        event.target.classList.toggle('highlighted')
      },

      /**
       * Handle keyboard movement events in fastbooking seach results
       * @param {Event} evt
       */
      handleKeyDown(evt) {
        let stroke
        let _ref
        let findMe
        stroke = (_ref = evt.which) != null ? _ref : evt.keyCode
        if (evt.shiftKey && stroke === 9) {
          // Tab + Shift (ir hacia atrás)
          evt.preventDefault()
          this.tabMovement('previousElementSibling', evt.target)
          return
        }
        switch (stroke) {
        case 9:
            // Tab
            evt.preventDefault()
            this.tabMovement('nextElementSibling', evt.target)
            break
          case 13:
            // Enter
            evt.preventDefault()
            if (currentDevice.isMobile) return false
            const currentHighlightElement =
              document.querySelectorAll('.active-result.highlighted-keyboard')[document.querySelectorAll('.active-result.highlighted-keyboard').length - 1]
          if (currentHighlightElement !== null) $(currentHighlightElement).trigger('mousedown')
            break
          case 38:
            // Up arrow
            evt.preventDefault()
            findMe = document.querySelectorAll('.active-result.highlighted-keyboard')[0]
            if (findMe !== null) {
              if (findMe.previousElementSibling !== null && findMe.previousElementSibling !== undefined && findMe.previousElementSibling.classList.contains('active-result')) {
                this.scrollContainerWhenKeyboardInteraction(findMe.previousElementSibling)
                setTimeout(function () {
                  findMe.classList.remove('highlighted-keyboard')
                  findMe.previousElementSibling.classList.add('highlighted-keyboard')
                }, 50)
            } else if (findMe.closest('.chosen-results--hotels') !== null) {
                findMe.classList.remove('highlighted-keyboard')
              findMe = findMe.closest('.chosen-results--hotels').previousElementSibling.querySelector('.active-result:last-child')
              findMe.closest('.first-column').scrollTop = 0
              findMe.classList.add('highlighted-keyboard')
            }
            }
            break
        case 40:
            // Down arrow
            evt.preventDefault()
            findMe = document.querySelectorAll('.active-result.highlighted-keyboard')[document.querySelectorAll('.active-result.highlighted-keyboard').length - 1]
            if (findMe !== null && findMe !== undefined) {
              if (findMe.nextElementSibling !== null && findMe.nextElementSibling.classList.contains('active-result')) {
                this.scrollContainerWhenKeyboardInteraction(findMe.nextElementSibling)
                setTimeout(function () {
                  findMe.classList.remove('highlighted-keyboard')
                  findMe.nextElementSibling.classList.add('highlighted-keyboard')
              }, 50)
              } else if (findMe.nextElementSibling === null && findMe.closest('.chosen-results--last-searches') !== null) {
                findMe.classList.remove('highlighted-keyboard')
                findMe = findMe.closest('.chosen-results--last-searches').nextElementSibling.querySelector('.active-result')
                findMe.classList.add('highlighted-keyboard')
            }
          } else {
              findMe = document.querySelector('.first-column .active-result')
              findMe.classList.add('highlighted-keyboard')
              document.querySelector('.first-column').focus()
          }
            break
        }
      },

      tabMovement(sibling, $inputElement) {
        const currentHighlightedItem = document.querySelector('.active-result.highlighted-keyboard')
        const $currentColumn = currentHighlightedItem?.closest('div[class*="-column"]')

        // Remove current highlighted item
        currentHighlightedItem && currentHighlightedItem.classList.remove('highlighted-keyboard')

        if ($currentColumn && !$currentColumn[sibling]) {
          // If there's no next or prev column, return focus to input element
          $inputElement.closest('.place-to-go')?.querySelector('[data-fake-select-click]')?.click()
        } else {
          // Focus on next column. Set first-column as default id there isn't current active column.
          const $nextColumn = $currentColumn ? $currentColumn[sibling] : document.querySelector('.chosen-results .first-column')
          $nextColumn.querySelector('.active-result')?.classList.add('highlighted-keyboard')
          $nextColumn.scrollTop = 0
          $nextColumn.trigger('focus')
        }
      },

      scrollContainerWhenKeyboardInteraction(element) {
        const maxHeight = parseInt($('.chosen-results').first().css('maxHeight'), 10)
        const $columnParent = $(element).closest('div[class*="-column"]')
        const visible_top = $columnParent.scrollTop()
        const visible_bottom = maxHeight + visible_top
        const high_top = $(element).position().top + $columnParent.scrollTop()
        const high_bottom = high_top + $(element).outerHeight()
        if (high_bottom >= visible_bottom) {
          return $columnParent.scrollTop(high_bottom - maxHeight > 0 ? high_bottom - maxHeight + 50 : 50)
        }
        if (high_top < visible_top) {
          return $columnParent.scrollTop(high_top - 90)
        }
      },

      changePlaceholder() {
        const inputTextByUser = document.querySelector('.input-text-by-user')
        // Change of the placeholder according to the initialisation parameter of the searches in FastBooking -> minimumSearchLength
        const inputTextByUserPlaceholder = inputTextByUser.placeholder.replace('%{number_letter}', this.minimumSearchLength)
        inputTextByUser.placeholder = inputTextByUserPlaceholder
      },

      deactivateUserHasScrolled() {
        if (currentDevice.isMobile) {
          // Avoids logic from line 2250 when this input is focused and the page scrolls natively
          sessionStorage.setItem('userHasScrolled', 'false')
        }
      },

      filterDestinations() {
        this.filteredDestinations = this.destinations_data.filter(destination => {
          return this.findSearchInTitleContent(destination.title, this.search)
        })
      },

      filterLastSearches() {
        if (this.last_searches_data !== null)
          this.filteredLastSearches = this.last_searches_data.filter(lastSearch => {
            return removeDiacritics(lastSearch.place_title).includes(removeDiacritics(this.search))
          })
      },

      filterHotelsList() {
        this.filteredHotels = this.hotels_data.filter(hotel => {
          return this.findSearchInTitleContent(hotel.title, this.search)
        })
      },

      filterSpecialties() {
        this.filteredSpecialties = this.specialties_data.filter(specialty => {
          return this.findSearchInTitleContent(specialty.title, this.search)
        })
      },

      filterAllSpecialtiesExceptMatch() {
        this.filteredAllSpecialtiesExceptMatch = this.specialties_data.filter(specialty => {
          return this.findInTitleContentReturnsNoMatch(specialty.title, this.search)
        })
      },

      filterFlightDestinations() {
        this.filteredFlightDestinations = this.flight_destinations.filter(destination => {
          return this.findSearchInTitleContent(destination.title, this.search)
        })
      },

      filterHotelsInPackage() {
        if (!Boolean(this.hotels_package)) return
        this.filteredHotelsInPackage = this.hotels_data.filter(hotel => {
          return this.hotels_package.package_hotels_ids.includes(hotel.id)
        })
      },

      setSearchTerm() {
        this.searchTerm = this.search.length ? `"${this.search}"` : ''
      },

      filterResults() {
        this.filterHotelsList()
        this.setSearchTerm()

        if (this.isFlightAndHotelsTabActive) {
          this.filterFlightDestinations()
        } else {
          this.filterDestinations()
          this.filterLastSearches()
          this.filterSpecialties()
          this.filterAllSpecialtiesExceptMatch()
        }
      }
    },
  }

  const app = createApp(chosen)
  app.mount(BASE_ID_SELECTOR)
  document.dispatchEvent(new Event(DESTINATION_SELECT_INIT_EVENT))

  if (callbacksAfterPlaceToGoBoxInitialization.length) {
    $.each(callbacksAfterPlaceToGoBoxInitialization, function (_, cb) {
      cb()
    })
    callbacksAfterPlaceToGoBoxInitialization = []
  }
}

function IsOwnChosenClicked(evt, selfObj) {
  if (selfObj !== undefined) {
    return selfObj.contains(evt.target) || evt.target == selfObj || (selfObj.childNodes != undefined && $.inArray(evt.target, selfObj.childNodes) >= 0)
  }
}

$(document)
  .on('click', 'body', function (evt) {
    const $chosen_selectors = $fastbooking.find('.chosen-select')

    // Ignore this event if keep chosen container opened is set
    if ($chosen_selectors.data('keep-opened-once')) {
      $chosen_selectors.removeData('keep-opened-once')
      return
    }

    // Close fastbooking chosen container when click in other page element
    const $chosen_element = currentDevice.isMobile ? $('.label-mobile-place-to-go')[0] : $('#desktop-place-to-go')[0]

    if (!IsOwnChosenClicked(evt, $chosen_element) && evt.target.className !== 'fake-select') {
      if ($('.chosen-container').hasClass('chosen-with-drop')) closePlaceToGoBox()
    }
  })
  .on('focusout', '.mobile-fastbooking-form .chosen-search input', function (evt) {
    evt.preventDefault()
    closePlaceToGoBox()
    setTimeout(IB.fastbooking.switchClearButtonPlaceToGo(), 500)
  })
  .on(
    'mousedown',
    '.input.place-to-go .group-option, .label-mobile-place-to-go .group-option, #mobile-hotels-selector .active-result, #vo_booking_place_to_go .active-result',
    function (e) {

      const $fastbooking = currentDevice.isMobile ? $('#fastbooking-mobile') : $('.fastbooking')
      const id = this.dataset.id
      const $chosen_selectors = $fastbooking.find('.chosen-select')


      setPlaceToGo($chosen_selectors, id)
      $(this).addClass('result-selected')

      closePlaceToGoBox()
    }
  )

export const setPlaceToGo = ($selector, value, fromHotelPage = false) => {

  const setPlace = () => {
    IB.fastbooking.changesBeforeHideChosen()

    const current_selection = document.querySelector('.result-selected')

    if (current_selection !== null) current_selection.classList.remove('result-selected')

    $selector.val(value)

    const e = currentDevice.isMobile ? document.getElementById('mobile-hotels-selector') : document.getElementById('vo_booking_place_to_go')

    if (e.selectedIndex == -1) return
    const destination = e.options[e.selectedIndex] === undefined ? '' : e.options[e.selectedIndex].text

    document.dispatchEvent(new CustomEvent(DESTINATION_SELECT_SET_INPUT_TEXT, { detail: destination }))

    destination !== '' ? document.querySelector('.chosen-single').classList.remove('chosen-default') : resetPlaceToGoBox()

    if (!fromHotelPage) $(e).trigger('change')

    setTimeout(IB.fastbooking.switchClearButtonPlaceToGo(), 500)
    IB.fastbooking.updateDiscountCounter(!!destination)
    IB.fastbooking_state.setState()
  }

  if (!isDestinationSelectEnabled) {
    initializeVuePlaceToGoBox(function () {
      FastbookingDateRangePicker.setPreventDatepickerOpen(true)
      setPlace()
    })
  } else {
    FastbookingDateRangePicker.setPreventDatepickerOpen(false)
    setPlace()
  }
}

// Listen to tab changes after flight tab was created
document.addEventListener(FASTBOOKING_FLIGHT_TAB_CREATED_EVENT, function (evt) {
  // Emit fastbooking mode change event when tab is clicked
  document.querySelectorAll('.js-fastbooking-tabs .js-tab').forEach(tab => {
    const activeTab = tab.dataset.type
    tab.addEventListener('click', () => {
      const tabType = activeTab === 'hotel' ? FASTBOOKING_TABS.HOTELS : FASTBOOKING_TABS.FLIGHTS
      document.dispatchEvent(new CustomEvent(FASTBOOKING_MODE_CHANGE_EVENT, { detail: tabType }))
    })
  })
})

export const openPlaceToGoBox = evt => {
  if (!isDestinationSelectEnabled) return
  const chosenContainer = document.querySelector('.chosen-container')
  IB.fastbooking.showingDropdown()
  chosenContainer.classList.add('chosen-with-drop')
  chosenContainer.querySelector('.chosen-results').classList.remove('hidden-to-tab-navigation')
  $('.chosen-container').find('input').first().val($('.chosen-container').find('input').first().val())
  $('.chosen-container').find('input').first().trigger('focus')

  const fbAdultsText = evt.target.closest('.js-fastbooking-container').querySelector('.fastbooking-adults-text')
  fbAdultsText?.classList.remove('hidden')
}

export const closePlaceToGoBox = () => {
  if (!isDestinationSelectEnabled) return
  const chosenContainer = document.querySelector('.chosen-container')

  const chosenContainerFirstColumn = chosenContainer.querySelector('.first-column')
  if(document.body.contains(chosenContainerFirstColumn)){
    chosenContainerFirstColumn.scrollTop = 0
  }

  chosenContainer.classList.remove('chosen-with-drop', 'chosen-container-active')
  chosenContainer.querySelector('.chosen-results').classList.add('hidden-to-tab-navigation')

  if (document.querySelector('#book-now.mouse-down') !== null) document.querySelector('#book-now').classList.remove('mouse-down')
  document.querySelector('.input-text-by-user').value = ''
  document.dispatchEvent(new Event(DESTINATION_SELECT_RESET_SEARCH))

  const highlightedKeyboardElement = document.querySelector('.active-result.highlighted-keyboard')
  if (highlightedKeyboardElement !== null && highlightedKeyboardElement !== undefined) highlightedKeyboardElement.classList.remove('highlighted-keyboard')
}

export const resetPlaceToGoBox = () => {
  if (!isDestinationSelectEnabled) return
  const airportTabActivate = document.querySelector('.js-destinia-tab.js-tab')
  if (airportTabActivate && airportTabActivate.classList.contains('active') && document.getElementById('hotel-name')) {
    const hotelName = document.getElementById('hotel-name').value
    document.querySelector('.input-text').classList.add('on-origin-input')
    document.dispatchEvent(new CustomEvent(DESTINATION_SELECT_SET_INPUT_TEXT, { detail: hotelName }))
    document.querySelector('.chosen-single').classList.add('chosen-default')
  } else {
    document.dispatchEvent(new Event(DESTINATION_SELECT_RESET_INPUT_TEXT))
  }
  document.querySelector('.chosen-single').classList.add('chosen-default')
  document.querySelector('.input-text-by-user').value = ''
  document.dispatchEvent(new Event(DESTINATION_SELECT_RESET_SEARCH))
  IB.fastbooking_state.setState()
}

/** This function is used to add to the dataset of a selector the ids corresponding
  * to the input entered from the fastbooking data.
  */
function addDataAttributesToInput(input, selector){
  let hotelName = getCleanedString(input)
  let hotelSelected, destinationSelected, specialtiesSelected

  /* We transfor fastbooking data to Json */
  const fastbookingData = JSON.parse(sessionStorage.getItem('fastbooking_data'))
  /* We take the different elements */
  const hotelData = fastbookingData.hotels[1]
  const destinationData = fastbookingData.destinations[1]
  const specialtiesData = fastbookingData.specialties[1]

  /*we filter by the element until we find a match with our input */
  const hotelNames = hotelData.filter(hotel => hotel.title_normalize.trim() === hotelName)
  const destinationNames = destinationData.filter(destination => destination.title_normalize === hotelName)
  const specialtiesNames = specialtiesData.filter(specialty => specialty.title_normalize === hotelName)

  /* if exists, we set the attribute with the corresponding id */
  if (hotelNames.length > 0) {
    hotelSelected = hotelNames[0]
    selector.setAttribute('data-hotel-id', hotelSelected.id)
  } else {
    selector.removeAttribute('data-hotel-id')
  }

  /** we compare the destination id of the hotel with the ids of
   * the fastbooking data destinations array, once found we get its
   * destination name and set it in the attribute
   */
  const destinationSelectedName = destinationData.find(destination => destination.id === hotelSelected?.data.destination)

  if (destinationSelectedName) {
    selector.setAttribute('data-hotel-destination', destinationSelectedName.title_normalize)
  } else {
    selector.removeAttribute('data-hotel-destination')
  }

  /* if exists, we set the attribute with the corresponding id */
  if (destinationNames.length > 0) {
    destinationSelected = destinationNames[0]
    selector.setAttribute('data-destination-id', destinationSelected.id)
  } else {
    selector.removeAttribute('data-destination-id')
  }

  /* if exists, we set the attribute with the corresponding id */
  if (specialtiesNames.length > 0) {
    specialtiesSelected =specialtiesNames[0]
    selector.setAttribute('data-specialties-id', specialtiesSelected.id)
  } else {
    selector.removeAttribute('data-specialties-id')
  }
}
